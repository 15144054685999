/* see https://necolas.github.io/react-native-web/docs/setup/ */
/* These styles make the body full-height */
:root {
  --primary-color: #c2cf00;
}

* {
  box-sizing: border-box;
}

html,
body {
  height: 100%;
}

/* These styles disable body scrolling if you are using <ScrollView> */
body {
  overflow: hidden;
}

/* These styles make the root element full-height */
#root {
  display: flex;
  height: 100%;
}

html,
body {
  font: 14px -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica,
    Arial, sans-serif;
}

a {
  text-decoration: none;
  color: #000;
}

th {
  text-align: left;
}

.react-datepicker-popper {
  z-index: 100 !important;
}

.draggable {
  cursor: grab !important;
}

.webprotocol h1,
.webprotocol h2,
.webprotocol h3,
.webprotocol h4,
.webprotocol h5,
.webprotocol h6,
.webprotocol a {
  color: var(--primary-color);
}

.webprotocol {
  max-width: 1024px;
  margin: 50px auto;
  line-height: 150%;
}

.crack-list-item .area {
  font-weight: bold;
}

.overview-photos,
.crack-list-items {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1.5rem;
}

.crack-list-items.follow-up {
  display: block;
}

.follow-up-crack-list-item-wrapper {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1.5rem;
  border-bottom: 1px solid color-mix(in srgb, var(--primary-color) 50%, transparent);
  padding: 24px 0;
}

.crack-list-item.original {
  grid-column: 1 / 2;
}

.crack-list-item.followUp {
  grid-column: 2 / 3;
}

.crack-list-items.street {
  grid-template-columns: 1fr 1fr 1fr 1fr;
}

.detail-photos {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  gap: 1rem;
}

.overview-photos .overview-photo img,
.crack-list-item .photo img,
.detail-photos .detail-photo img, 
.overview-maps .overview-map img {
  width: 100%;
}

.crack-list-item .photo {
  position: relative;
  overflow: hidden;
}

.crack-list-item .photo .crack-mark {
  position: absolute;
  border: 5px solid var(--primary-color);
  border-radius: 50%;
}

.with-line-breaks {
  white-space: pre-line;
}

.vermflex-popover {
  left: 50px !important;
}
